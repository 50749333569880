.send{
  display: grid;
  grid-template-columns:  auto 60px;
}

.icon{
  width: 50px;
  height: 50px;
  margin-left: 5px;
}

.progress{
  margin-left: 5px;
}
