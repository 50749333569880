.input{
  position: fixed;
  bottom: 0;
  width: 100%;

}

.area{
  height: calc(100vh - 50px);
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
