.one{
  display: grid;
  grid-template-columns: 30px auto;
  height: 40px;
  line-height: 40px;
  padding-left: 10px;
}

.s{
  color: white;
}

.r{
  color: pink;
}
